import React from 'react';
import {NoResult} from "../../../components";
import {TabDefinition} from "./index";

interface Props {
    gift: GiftItem;
    selectedTab: TabDefinition;
    paramsStatus: string;
    showWaveList: boolean;
    waves: Wave[];
    loading?: boolean
}

const EmptyState = ({ gift, selectedTab, paramsStatus, showWaveList, waves, loading }: Props) => {

    const waveEmptyStateTitle = () => {
        switch (selectedTab.name) {
            case 'Declined':
                return `Your declined creators will appear here`;
            case 'New':
                if (gift.status === 'CLOSED') {
                    return 'Un-hide your gift to match with new creators';
                } else if ((gift.wave_count || 0) > 0) {
                    return 'There are no new matches right now';

                } else if (gift.status === 'CANCELED') {
                    return  'You have succesfully deleted this gift';
                
                } else if (gift.status === 'SCHEDULED') {
                    return 'Your gift is scheduled to go live in the next 24 hours.'
                } else {
                    return 'Congratulations! Your gift is live and you will start receiving matches soon!';
                }
            case 'Selected':
                return 'When you select creators from the New or Shortlist tab to collaborate with, their profiles will appear here!'
            case 'Shortlist':
                return 'Your shortlist contains creators you\'re thinking of working with'
            default:
                throw new Error('missing tab ' + selectedTab.name);
        }
    };

    const waveEmptyStateMessage = () => {
        switch (selectedTab.name) {
            case 'Declined':
                return `Declined creators never receive a message when they are declined unless you message them`;
            case 'New':
                if (gift.status === 'CLOSED') {
                    return 'You won\'t receive new matches until your gift is open again';
                } else if ((gift.wave_count || 0) > 0) {
                    return 'New matches will appear here.';
                } else {
                    if (gift.status === 'SCHEDULED') {return `In order for you to see a variety of different creators, we've spaced out your gift approval times.`}
                    return 'When you match with new creators you can add them to your shortlist \n& then select them for gifting.';
                }
            case 'Selected':
                return 'Selected creators receive a message saying they are being gifted.'
            case 'Shortlist':
                return 'Shortlisted creators will not be notified unless you message them. When selected, they receive a message saying they are being gifted.'
            default:
                throw new Error('missing tab ' + selectedTab.name);
        }
    };

    if (selectedTab.id === 'new' && gift.waves_by_status.PENDING) {
        return null;
    }

    let image: string;
    let title: string;
    let message: string

    if (showWaveList) {
        if (!loading && waves.length === 0) {
            image = 'sandwatch';
            title = waveEmptyStateTitle();
            message = waveEmptyStateMessage();
        } else {
            return null;
        }
    } else {
        image = 'sandwatch';

        switch (paramsStatus) {
            case 'draft':
                title = 'Please submit your gift.\nOnce we approve, you can get applications.';
                break;

            case 'submitted':
                title = 'We are reviewing your gift.\nOnce we approve, you can get applications.';
                break;

            case 'rejected':
                title = 'Please re-submit your gift.\nOnce we approve, you can get applications.';
                break;

            default: title = '';
        }
        message = '';
    }

    return (<NoResult image={image as any} title={title} message={message} />);
};

export default EmptyState;

import {MouseEvent, useState, useMemo, useEffect} from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Button, PictureFrame, Badge } from 'app/components';
import {AcceptIcon, CheckIcon, CloseIcon, PlusIcon} from 'app/icons';
import {GiftModal, GiftModelType} from 'app/components/GiftModal';
import {useLocalStorage, useMediaQuery} from "usehooks-ts";
import {formatEngagementRate, formatFollowerCount} from "../../../../utils/numberFormatting";
import { API } from 'api';
import errorReporter from 'services/errorReporter';
import instagramLogo from "../../../../images/instagram.svg";
import tiktokLogo from "../../../../images/tiktok.svg";
import youtubeLogo from "../../../../images/youtube.svg";
import giftedLogo from "../../../../images/gifted.svg";
import Modal from 'app/components/Modal';
const nudgeText = 'has sent a nudge. Please respond ASAP :)';

interface Props {
    id: string;
    name: string | null;
    headshotImage: string | null;
    customClass?: string;
    bio?: string;
    matchPercentage: number;
    tabId?: string;
    selected?: boolean;
    loading?: boolean;
    onClick?(id: string): void;
    onStatusUpdate?(status: string): void;
    badgeCounts: BadgeCounts;
    index: number;
    withdrawn: boolean;
    waveStatus: string;
    wave?: Wave;
    gift?: any;
    brand?: BrandProfile;
}

const logo: any = {
    INSTAGRAM: instagramLogo,
    TIKTOK: tiktokLogo,
    YOUTUBE: youtubeLogo,
    GIFTED: giftedLogo
}


type WaveActionParam = 'accept' | 'shortlist' | 'reject' | 'complete';

export function WaveListItem({
    id,
    name,
    headshotImage,
    matchPercentage,
    customClass,
    selected,
    loading,
    onClick,
    onStatusUpdate,
    badgeCounts,
    index,
    withdrawn,
    waveStatus,
    wave,
    gift,
    brand,
}: Props) {
    const animation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            loading: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.9, y: 60 },
            show: { opacity: 1, scale: 1, y: 0 }
        }
    };

    const [waveActionConfirmationState, setWaveActionConfirmationState] = useLocalStorage('wave-action-confirmation-state', {
        shortlist: true,
        complete: true,
        reject: true,
    } as { [prop: string]: boolean });

    const isCompleted = useMemo(() => waveStatus === 'COMPLETED', [waveStatus]);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedWaveListAction, setSelectedWaveListAction] = useState('');
    const smallDevice = useMediaQuery('(max-width: 900px)');
    const [clickedNudge, setClickedNudge] = useState(false);
    const [showNudgeModal, setShowNudgeModal] = useState(false);

    const [highestFollowers, setHighestFollowers] = useState(0);
    const [highestEngagement, setHighestEngagement] = useState<any>(0);
    const [socialType, setSocialType] = useState(null);
    const [showNotChattedModal, setShowNotChattedModal] = useState(false);
    const [showHideForeverModal, setShowHideForeverModal] = useState(false);
    const [tempHide, setTempHide] = useState(false);

    useEffect(() => {

        let profileWithHighestFollowerCount: any;
        let followersCount = 0;
        let highestSocialType;
        if (!wave?.influencer?.social_profiles) {
            return;
        }


        for (const profile in wave?.influencer?.social_profiles) {
            if (profile) {
                const waveProf = wave?.influencer?.social_profiles[profile];
                if (waveProf && waveProf.followers && waveProf.status !== 'DISCONNECTED') {
                    if (parseInt(waveProf.followers) > followersCount) {
                        followersCount = (parseInt(waveProf.followers));
                        profileWithHighestFollowerCount = waveProf
                        //@ts-ignore
                        highestSocialType = isNaN(profile) ? profile : wave?.influencer?.social_profiles[profile].platform
                    }    
                }
            }
        }

        if (profileWithHighestFollowerCount) {
            // @ts-ignore
            setHighestFollowers(followersCount);
            // @ts-ignore
            setHighestEngagement(profileWithHighestFollowerCount.engagement_rate);         
            // @ts-ignore
            if (!parseInt(highestSocialType) && highestSocialType !== 0) {
                // @ts-ignore
                setSocialType(highestSocialType)
                console.log('wave.influencer.social_profiles',wave.influencer.social_profiles, highestSocialType)
            }

            // handleAnalyticsChange({id: profileWithHighestFollowerCount?.platform, name: profileWithHighestFollowerCount?.platform});
        }


    }, [wave?.influencer?.social_profiles])


    const handleAcceptReject = (
        status: WaveActionParam,
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();

        if (status === 'accept' && wave?.status === 'SUBMITTED' && (wave.bubbles && wave?.bubbles.length === 1)) {
            console.log('no chat');
            setShowNotChattedModal(true);
            setSelectedWaveListAction(status)
            return;
        }

        if (status === 'accept' && wave?.is_recently_booked) {
            setSelectedWaveListAction(status);
            setShowConfirmationModal(true);
        } else {
            if (waveActionConfirmationState[status]) {
                setSelectedWaveListAction(status);
                setShowConfirmationModal(true);
                if (status !== 'complete') {
                    const newState = {
                        ...waveActionConfirmationState,
                    };
                    newState[status] = false;
                    setWaveActionConfirmationState(newState);
                }
            } else {
                onStatusUpdate && onStatusUpdate(status);
            }
        }
    }

    const handleHideForever = () => {
        if (localStorage.getItem(`hidden-hide-forever-modal`) === 'TRUE') {
            confirmHideForever();
        } else {
            setShowHideForeverModal(true);
        }
    }

    const confirmHideForever = () => {
        if (brand) {
            API.Influencer.neverShowAgain(brand.uid,id).then(() => {
                setShowHideForeverModal(false);
                setTempHide(true)
            }).catch((e) => {
                errorReporter.report('Error confirming hide forever', e)
            })
        }

    }

    const handleNudge = () => {
        API.Wave.sendNudge(gift.item.uid as string, wave?.uid as string, brand as BrandProfile).then(() => {
            setSelectedWaveListAction('')
            setClickedNudge(true);
            setShowNudgeModal(false);
            setTimeout(() => {
                onClick && onClick(id);
            }, 400)
        }).catch(() => {
            errorReporter.report('Could not send nudge');
        })
    }

    const handleCloseModal = () => {
        setShowConfirmationModal(false);
        setSelectedWaveListAction('')
    }

    const handlePrimaryModal = () => {
        if (onStatusUpdate) {
            onStatusUpdate(selectedWaveListAction)
        }
        setShowConfirmationModal(false);
        setSelectedWaveListAction('')
    }

    const mapActionToGiftModalType: GiftModelType | undefined = useMemo(() => {
        switch (selectedWaveListAction) {
            case 'accept':
                if (wave?.is_recently_booked) {
                    return 'creator-recently-booked-accept'
                }
                return 'wave-list-action-accept';
            case 'shortlist':
                return 'wave-list-action-shortlist';
            case 'complete':
                return 'wave-list-action-complete';
            case 'reject':
                return 'wave-list-action-reject';
            default:
                return undefined;
        }

    }, [selectedWaveListAction, wave]);

    const count = useMemo<number>(() => {
        const count = badgeCounts[wave?.uid || ''];
        return count || 0;
    }, [wave, badgeCounts]);

    useEffect(() => {

    })

    return (
        
        <>
            {!tempHide ? (
                <Container
                className={customClass}
                {...animation}
                $selected={selected}
                $loading={loading}
                onClick={() => onClick && onClick(id)}
            >

                {smallDevice ? (
                    <MobileLine>
                        <Desc className='mobile'>
                            <StyledPictureFrame
                                width={60}
                                height={60}
                                image={headshotImage || ''}
                                loading={loading}
                            />

                            <Info className='mobile'>
                                <span>{name}</span>
                                <Details $matchPercentage={matchPercentage}>
                                    {!loading && (
                                        <span>
                                            {highestFollowers ? `${formatFollowerCount(highestFollowers as number)} Followers` : ''} {' '}
                                            {highestEngagement ? `${formatEngagementRate(highestEngagement as number)} Eng.` : ''}
                                        </span>
                                    )}
                                </Details>

                            </Info>
                        </Desc>

                    </MobileLine>

                ) : (
                    <>
                        <Desc>
                            <StyledPictureFrame
                                width={60}
                                height={60}
                                image={headshotImage || ''}
                                loading={loading}
                            />

                            <Info>
                                <span>{name}</span>
                            </Info>
                        </Desc>

                        <Details $matchPercentage={matchPercentage}>
                            {!loading && (         
                                    <span>
                                        {socialType ? <img style={{ transform: `translateY(${socialType !== 'TIKTOK' ? '5px' : '3px'}) translateX(-2px)` }} alt={socialType} src={`${logo[socialType]}`} /> : null}
                                        {highestFollowers ? `${formatFollowerCount(highestFollowers as number)} Followers` : ''} <br />
                                        {highestEngagement ? `${formatEngagementRate(highestEngagement as number)} Engagement` : ''}
                                    </span>
                            )}
                        </Details>

                    </>
                )}

                <Actions onClick={(event) => event.preventDefault()}>

                    {waveStatus === 'REJECTED' && (
                        <Button
                            className={`reject-button-${index} hide-forever`}
                            size={'small'}
                            tooltip={!smallDevice ? 'Decline' : ''}
                            onClick={(event) =>
                                handleHideForever()}
                        >
                        Hide forever
                        </Button>
                    )}

                    {waveStatus !== 'REJECTED' && !isCompleted && (
                        <Button
                            className={`reject-button-${index} smallBtn`}
                            theme={'outline'}
                            size={'small'}
                            iconOnly={true}
                            tooltip={!smallDevice ? 'Decline' : ''}
                            onClick={(event) =>
                                handleAcceptReject('reject', event)}
                        >
                            <CloseIcon />
                        </Button>
                    )}

                    <Button
                        className={`shortlist-button-${index}`}
                        theme={'outline'}
                        size={'small'}
                        iconOnly={true}
                        tooltip={!smallDevice ? 'Nudge' : ''}
                        onClick={() =>
                        setShowNudgeModal(true)}
                    >
                        Nudge
                    </Button>


                    {(waveStatus !== 'SHORTLISTED' && waveStatus !== 'ACCEPTED' && !withdrawn && !isCompleted) && (
                        <Button
                            className={`shortlist-button-${index} smallBtn`}
                            theme={'outline'}
                            size={'small'}
                            iconOnly={true}
                            tooltip={!smallDevice ? 'Shortlist' : ''}
                            onClick={(event) =>
                                handleAcceptReject('shortlist', event)}
                        >
                            <PlusIcon />
                        </Button>
                    )}

                    {(waveStatus !== 'ACCEPTED' && !isCompleted && !loading) && (
                        <Button
                            className={`select-button-${index}`}
                            size={'small'}
                            iconOnly={false}
                            onClick={(event) =>
                                handleAcceptReject('accept', event)}
                            disabled={withdrawn}
                        >
                            {smallDevice ? (
                                !withdrawn ? <AcceptIcon /> : 'Withdrawn'
                            ) : (
                                <>
                                    {!withdrawn && <AcceptIcon />}
                                    {waveStatus === 'REJECTED' ? '' : withdrawn ? 'Withdrawn' : 'Accept'}
                                </>
                            )}
                        </Button>
                    )}

                    {(waveStatus === 'ACCEPTED' || waveStatus === 'COMPLETED') && (
                        <Button
                            className={`select-button-${index}`}
                            size={'small'}
                            iconOnly={false}
                            onClick={(event) =>
                                handleAcceptReject('complete', event)}
                            disabled={isCompleted}
                        >
                            {smallDevice ? (
                                <StyledCheckIcon />
                            ) : (
                                <>
                                    {!isCompleted && <StyledCheckIcon />}
                                    {isCompleted ? 'Completed' : 'Complete'}
                                </>
                            )}
                        </Button>
                    )}
                </Actions>
                {(count > 0) ? (
                    <WaveCountBadge>{count}</WaveCountBadge>
                ) : null}


            </Container>

        ) : null}

            {mapActionToGiftModalType && (
                <GiftModal
                    primaryAction={{name: `Yes, I'm sure`, action: () => handlePrimaryModal()}}
                    secondaryAction={{name: 'Cancel', action: () => handleCloseModal()}}
                    show={showConfirmationModal}
                    onClose={() => handleCloseModal()}
                    type={mapActionToGiftModalType}
                />
            )}


            {showNudgeModal && (
                <GiftModal
                    primaryAction={{name: `Send nudge`, action: () => handleNudge()}}
                    secondaryAction={{name: 'Cancel', action: () => setShowNudgeModal(false)}}
                    show={showNudgeModal}
                    onClose={() => setShowNudgeModal(false)}
                    type={'nudge'}
                />
            )}

            {showNotChattedModal && (
                <GiftModal
                    primaryAction={{name: `Yes, I'm sure`, action: () => handlePrimaryModal()}}
                    secondaryAction={{name: 'Cancel', action: () => setShowNotChattedModal(false)}}
                    show={showNotChattedModal}
                    onClose={() => setShowNotChattedModal(false)}
                    type={"not-chatted"}
                />            
            )}

            {showHideForeverModal && (
                <Modal
                    primaryAction={{name: 'Hide this creator forever', action: () => confirmHideForever()}}
                    newFeature={true}
                    icon={'not-allowed'}
                    title="Hide forever"
                    description="Hidden creators will never be able to apply for any of your gifts again."
                    show={showHideForeverModal}
                    dontShowAgainToggle={true}
                    secondaryAction={{name: 'cancel', action: () => setShowHideForeverModal(false)}}
                />
            )}


        </>
    );
}


const StyledPictureFrame = styled(PictureFrame)`
    box-shadow: var(--shadow-2);

    @media (max-width:900px) and (min-width:0px) {
        width: 40px;
        height: 40px;
    }
`;

const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: #fff;
  }
`

const Info = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        
        &:first-child {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            color: var(--grey-7);
        }
    }

    &.mobile {
        span {
            &:first-child {
                font-size: 12px;
                line-height: 14px;
            }

            &:last-child {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
`;

const WaveCountBadge = styled(Badge)`
    position: absolute;
    top: -6px;
    right: -6px;
    letter-spacing: 0px;
    border-radius: 8px;
    background-color: var(--error-active);
    color: var(--text-alt);
`;


const Desc = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    width: 40%;

    > div:first-child {
        flex-shrink: 0;
    }

    &.mobile {
        flex-direction: row;
    }
`;

const Details = styled.div<{
    $matchPercentage: number
}>`
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 30%;
    margin-right: 30px;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        
        &:first-child {
            font-size: 14px;
            font-weight: 600;
            place-self: center;
            line-height: 24px;
            color: var(--grey-6);
        }
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    // width: 35%;
    justify-content: right;

    button {
        height: 44px;
       
        svg {
            width: 20px;
            height: 20px;
        }
    }
    
    .smallBtn {
        width: 44px;
    }

    .hide-forever {
        width: 130px;
    }
`;

const Container = styled(motion.div) <{
    $selected?: boolean;
    $loading?: boolean;
}>`
    position: relative;
    flex-shrink: 0;
    display: flex;
    height: 76px;
    padding: 0 8px;
    border: 1px solid var(--grey-4);
    border-radius: 16px;
    transition: border 300ms ease;
    user-select: none;
    cursor: pointer;

    &:hover {
        border: 1px solid var(--blue);
    }

    &:active:not(:focus-within) {
        transition: transform 300ms ease;
        transform: scale(0.95) !important;
    }

    ${(props) => props.$selected && `
        border-color: var(--blue);
        background-color: var(--grey-2);
    `}

    ${(props) => props.$loading && `
        pointer-events: none;

        ${Info} span,
        ${Details} span,
        ${Actions} button {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '##############';
                visibility: hidden;
            }
        }

        ${Info} span {
            &:first-child {
                margin-bottom: 2px;
            }

            &:last-child {
                width: 80%;
            }
        }

        ${Details} span {
            &:before {
                content: '########';
            }

            &:last-child {
                border-radius: 8px;
            }
        }

        ${Actions} button {
            border: none;
            border-radius: 16px;
            color: transparent;
            transition: none;

            &:before {
                content: '##';
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        height: auto;
        justify-content: space-between;
        align-items: center;
        padding: 8px 8px;


        ${Details} {
            flex-direction: row;
            text-align: center;
            justify-content: center
            width: 100%;
            overflow: visible;

            span {
                white-space: wrap;
                overflow: visible;
            }
        }

        ${Actions} {
            transform: scale(.7);
        }
    }
`;

const MobileLine = styled.div`
    display: flex;
    width: 100%;


    ${Desc} {
        width: 100%;
        overflow: visible;
        column-gap: 4px;
    }
    
    ${Details} {
        width: 100%;
    }
`

import {
    ChangeEvent,
    MouseEvent,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';

import { CameraAddIcon } from 'app/icons';
import { Button } from '../Button';
import { ProfileFrame } from '../ProfileFrame';
import { ProfileUploadModal } from '../ProfileUploadModal';
import { GiftImageUploadModal } from '../GiftImageUploadModal';
import {Crop} from "react-image-crop";
import {useAppSelector} from "../../../store";
import prettyBytes from 'pretty-bytes';

interface Props {
    name: string;
    image: string;
    isGift?: boolean;
    allowVideo?: boolean;
    showUpload?: boolean;
    disabled?: boolean;
    className?: string;
    onChange: (name: string, image: File, thumbnail?: Blob, crop?: Crop) => void;
    flexColumn?: boolean;
}

export function FormMedia({
    name,
    image,
    isGift,
    allowVideo,
    showUpload,
    disabled,
    className,
    onChange,
    flexColumn
}: Props) {
    const id = `media-input-${name}`;
    const inputRef = useRef<HTMLInputElement>(null);
    const [modalState, setModalState] = useState<Dynamic>({ show: false });
    const [error, setError] = useState<boolean>(false);
    const [sizeError, setSizeError] = useState<boolean>(false);
    const [fileSizeError, setFileSizeError] = useState<boolean>(false);

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const allowedTypes = useMemo(() => {
        return settings?.allowedMimeTypes?.images || [];
    }, [allowVideo, settings]);


    const allowedImageTypes = useMemo(() => {
        return settings?.allowedMimeTypes?.images || [];
    }, [settings]);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (!showUpload || !disabled) {
            setError(false);
            setSizeError(false);
            setFileSizeError(false);

            inputRef.current?.click();
            event.preventDefault();
        }
    }

    const handleChange = (file: File, thumbnail?: Blob, crop?: Crop) => {
        URL.revokeObjectURL(modalState.image as string);
        const img = new Image()
        img.src = modalState.image

        setModalState({ show: false });
        onChange(name, file, thumbnail, crop);
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (files) {
            const file = Array.from(files)[0];

            if (file.size > (settings?.files.max_upload_size as number)) {
                setFileSizeError(true)
                return
            }

            if (allowedTypes.includes(file.type)) {
                if (allowedImageTypes.includes(file.type)) {
                    const img = new Image();
                    img.onload = () => {

                        if (isGift) {
                            if (img.width < 271 && img.height < 406  && isGift) {
                                setSizeError(true);
                            } else {
                                setModalState({
                                    show: true,
                                    image: URL.createObjectURL(file),
                                    file: file,
                                });
                            }
                        } else {
                            if (img.width < 300 && img.height < 300) {
                                setSizeError(true);
                            } else {
                                setModalState({
                                    show: true,
                                    image: URL.createObjectURL(file),
                                    file: file,
                                });
                            }
                        }



                    }
                    img.src = URL.createObjectURL(file)
                } else {
                    onChange(name, file);
                }
            } else {
                setError(true);
            }
        }
    }

    const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
        event.currentTarget.value = '';
    }

    return (
        <>
        <Container
            data-testid={'form-media-component'}
            $disabled={disabled}
            $error={error}
            className={className}
        >
            <Content style={{flexDirection: `${flexColumn ? 'column' : 'row'}`}}>
                <StyledProfileFrame
                    id={id}
                    image={image}
                    width={84}
                    height={84}
                    isUpload={true}
                    onClick={(event) => handleClick(event)}
                />

                {showUpload && (
                    <>
                        {flexColumn ? <div style={{marginTop: '16px'}}></div> : null}
                        <StyledButton
                            theme={'aqua'}
                            size={'small'}
                            disabled={disabled}
                            onClick={handleClick}
                        >
                            <CameraAddIcon />

                            <span>
                                {image ? 'Change Photo' : 'Upload'}
                            </span>
                        </StyledButton>
                    </>
                )}
            </Content>

            <input
                ref={inputRef}
                type={'file'}
                id={id}
                name={''}
                accept={allowedTypes.join(', ')}
                hidden={true}
                disabled={disabled}
                onClick={handleInputClick}
                onChange={handleInputChange}
            />


            {isGift ?
                (
                    <GiftImageUploadModal
                        image={modalState.image}
                        file={modalState.file}
                        show={modalState.show}
                        onClose={() => setModalState({ show: false })}
                        primaryAction={{
                            name: 'Save',
                            action: handleChange
                        }}
                        secondaryAction={{
                            name: 'Cancel',
                            action: () => setModalState({ show: false })
                        }}
                    />
                ) : (
                    <ProfileUploadModal
                        image={modalState.image}
                        show={modalState.show}
                        onClose={() => setModalState({ show: false })}
                        primaryAction={{
                            name: 'Save',
                            action: (file) => handleChange(file)
                        }}
                        secondaryAction={{
                            name: 'Cancel',
                            action: () => setModalState({ show: false })
                        }}
                    />
                )
            }


        </Container>


            {(sizeError) && (
                <Error>
                    {
                        isGift ? `Please upload a JPG or PNG image with a width of at least 270px and a height of 406px` : `Please upload a JPG or PNG image with a width \n of at least 300px and a height of 300px`
                    }
                </Error>
            )}

            {(fileSizeError) && (
                <Error>
                    {
                        `File sizes cannot exceed ${prettyBytes(settings?.files.max_upload_size as number)}`
                    }
                </Error>
            )}


            {(error) && (
                <Error>
                    {allowVideo ?
                        `We don't support that format.\nPlease JPG or PNG for image with a width of at least 270px and MP4 for video` :
                        `We don't support that format.\nPlease JPG or PNG for image with a width of at least 270px`
                    }
                </Error>
            )}

        </>
    );
}


const StyledButton = styled(Button)`
    svg {
        width: 20px;
        height: 20px;

        path {
            fill: var(--blue);
        }
    }
`;

const StyledProfileFrame = styled(ProfileFrame)``;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
`;

const Error = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    color: var(--error-active);
    text-align: center;
`;

const Container = styled.div<{
    $error?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    ${(props) => props.$error && `
        ${StyledProfileFrame} {
            > svg path {
                stroke: var(--error-active);
            }

            div {
                > svg path {
                    fill: var(--error-active);
                }

                span {
                    color: var(--error-active);
                }
            }
        }
    `}

    ${(props) => props.$disabled && `
        pointer-events: none;
    `}
`;

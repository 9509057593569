import {
    FormEvent,
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';

import { useAppSelector } from 'store';
import { Helpers } from 'utils';
import {
    Alert,
    Button,
    EmptyPlaceholder,
    FormSwitch,
    FormTextarea
} from 'app/components';
import {deliverableToIcon} from "../../../utils/mapping";
import listify from 'listify';
import { useMediaQuery } from 'usehooks-ts';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import './index.css'
import { useNavigate } from 'react-router-dom';
import * as axios from 'axios';
import { giftAPI } from 'api/gift';
import errorReporter from 'services/errorReporter';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from 'services/settingsService';

interface Props {
    data?: InviteForm;
    status?: string;
    disabledActions?: boolean;
    disabledInputs?: boolean;
    loading?: boolean;
    primaryAction: GiftFormAction;
    secondaryAction: GiftFormAction;
    propagateFormToGiftView?: any;
    noBottomButtons?: boolean;
}

interface InviteForm {
    brand: BrandProfile | null;
    giftSlug?: string | null;
}


interface DeliverablesErrors {
    deliverables?: string;
}

export function InviteCreatorsForm({
    data,
    status,
    disabledActions,
    disabledInputs,
    loading,
    children,
    primaryAction,
    secondaryAction,
    propagateFormToGiftView,
    noBottomButtons
}: PropsWithChildren<Props>) {
    const rightContent = useRef<HTMLDivElement>(null);
    const emailList = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()
    const textRef = useRef(null);


    const [form, setForm] = useState(data);
    const [formErrors, setFormErrors] = useState<DeliverablesErrors>({});


    const [emails, setEmails] = useState<string[]>([]);
    const [focused, setFocused] = useState(false);
  

    const [inviteEmail, setInviteEmail] = useState('')

    const smallDevice = useMediaQuery('(max-width: 900px)')

    const [copied, setCopied] = useState(false)


    useEffect(() => {
        if (data?.brand) {
            const brand = data.brand
            setInviteEmail(`${brand.name} has invited you to collaborate with them on their latest gifted campaign on #gifted.

Simply click the link below, download the app, and apply for the gift, so they can organize the details with you :) 

There’s no fees to use #gifted and you can match with hundreds of brands for contra collaborations.

We look forward to having you in the community!`)
        }
    }, [data])

    useEffect(() => {
        if (propagateFormToGiftView) {
            propagateFormToGiftView(form)
        }
    }, [form])

    useEffect(() => {
        if (emails.length > 30 && emailList.current) {
            emailList.current.style.overflowY = 'scroll';
            emailList.current.style.height = '300px';

        } else if (emailList.current) {
            emailList.current.style.overflowY = 'auto';
            emailList.current.style.height = 'auto';
        }
    }, [emails])

    useEffect(() => {
        const rightContentEl = rightContent.current;
        const parentEl = rightContentEl?.parentElement;

        if (rightContentEl && parentEl && rightContentEl) {
            const rightContentRect = rightContentEl.getBoundingClientRect();
            const parentRect = parentEl.getBoundingClientRect();

            if (rightContentRect.height > parentRect.height) {
                parentEl.style.height = 'auto';
            }
        }

        return () => {
            if (parentEl) {
                parentEl.style.removeProperty('height');
            }
        }
    }, []);

    const {
        global: { settings },
      } = useAppSelector((state) => state);
    
      const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        analyticsEvents.giftInviteCreator({
            data,
            invited: true
        })
        primaryAction.action();
    }

    const handlePrimaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        primaryAction.action();
    }

    const handleSecondaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        analyticsEvents.giftInviteCreator({
            data,
            invited: false
        })
        secondaryAction.action();
    }

    const handleChangeEmail = (name: string, value: string) => {
        setInviteEmail(value)
    }

    const copyText = () => {
        if (!textRef) return
        //@ts-ignore
        textRef?.current?.select()
        document.execCommand('copy')
        setCopied(true);
        window.setTimeout(() => {
                        //@ts-ignore
            window.getSelection().removeAllRanges();
            setCopied(false)
        }, 1500)
    }

    const submitInviteCreators = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (data?.brand?.uid) {
            giftAPI.inviteCreators(data.brand.uid, emails, inviteEmail).then(() => {
                navigate('/')
            }).catch((e: any) => {
                errorReporter.report('Invite creators failed',e)
            })
        }

    }

    return (
        <>
            <LeftContent>
                {children}

                <Form onSubmit={handleSubmit}>
                   
                <EmailInputs ref={emailList}>
                    <Label>
                        Enter a comma seperated list of emails
                    </Label>
                    <ReactMultiEmail 
                        placeholder='Input list of emails'
                        emails={emails}
                        onChange={(_emails: string[]) => {
                        setEmails(_emails);
                        }}
                        autoFocus={true}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        getLabel={(email, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                            </span>
                            </div>
                        );
                        }}
                    />
                </EmailInputs>

                    <Label>
                        Invitation email:
                    </Label>
                    
                    <FormTextarea
                        name={'message'}
                        label={''}
                        placeholder={'...'}
                        value={inviteEmail}
                        autoHeight
                        limit={600}
                        required={false}
                        onChange={handleChangeEmail}
                    />

                {data?.giftSlug ? (
                    <div style={{color: '#262161', fontWeight: 300}}>
                        <div>
                        <br />
                            Share gift:
                            <CopyInput>
                                <input ref={textRef} value={`https://hashgifted.com/gifts/${data.giftSlug}`} onClick={() => copyText()} />
                                <button onClick={() => copyText()}>{copied ? 'Copied' : 'Copy'}</button>
                            </CopyInput>
                            
                        </div>
                        <br />
                        <div>Your public link will be live once your gift is approved.</div>
                    </div>
                ) : null}


                    {formErrors.deliverables && (
                        <p style={{color: '#ff647c', fontWeight: 400, fontSize: 14}}>{formErrors.deliverables}</p>
                    )}

                    {(!disabledActions && !disabledInputs) && (!noBottomButtons) && (
                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                loading={loading}
                                onClick={(e) => submitInviteCreators(e)}
                            >
                                {primaryAction.name}
                            </StyledButton>

                            <StyledButton
                                theme={'outline'}
                                size={'medium'}
                                loading={loading &&
                                    status === 'saving-draft'}
                                onClick={handleSecondaryActionClick}
                            >
                                {secondaryAction.name}
                            </StyledButton>
                        </FormActions>
                    )}
                </Form>

            </LeftContent>

            <RightContent ref={rightContent}>
                <EmptyPlaceholder image={'girl-2'} />
            </RightContent>
        </>
    );
}

const EmailInputs = styled.div`
    // height: 300px;
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 300;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    line-height: 24px;
    color: var(--grey-9);

    span {
        &:first-of-type {
            order: 5;
        }

        &:last-of-type {
            order: 4;
            color: var(--error-active);
        }
    }
`;


const DeliverableHeader = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
`;

const DeliverableDesc = styled.div`
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: var(--grey-7);
`;

const DeliverableInfo = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    span {
        display: flex;

        &:last-child {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--grey-9);
        }
    }
`;

const StyledSwitch = styled(FormSwitch)`
    margin-left: auto;
`;

const Delieverable = styled.div`
    display: flex;
    padding: 8px 16px;
    border-radius: 12px;
    background: var(--grey-2);
`;

const StyledButton = styled(Button)`
    width: 186px;
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-top: auto;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;

        button {
            width: 100%;
            margin-top: 12px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 100%;
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px 40px;
  @media (max-width:900px) and (min-width:0px) {
    padding: 24px 24px 40px;
  }
`;

const RightContent = styled.div`
    grid-area: right-content;
    border-left: 1px solid var(--grey-4);

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const CopyInput = styled.div`
    display: flex;
    position: relative;
    border-radius: 14px;
    padding: 6px 8px;
    align-items: center;
    margin-top: 12px;
    color: var(--blue) !important;
    font-weight: 500;
    background: #f1f4ff;

    &:focus {
        border: solid 1px var(--blue);
    }

    div {
        color: var(--blue) !important;
    }

    input {
        border: none;
        width: 100%;
        background: transparent;
        font-size: 14px;
        font-weight: 300;
        color: var(--blue);
    }

    button {
        position: absolute;
        right: 8px;
        background: none;
        border: solid 1px var(--blue);
        padding: 0px 2px;
        border-radius: 6px;
        cursor: pointer;
        background: white;
        z-index: 5;
    }
`

import styled from 'styled-components';

import { Helpers, Mapping } from 'utils';
import {
    Button,
    FormSelect,
    OptionProps,
    ProfileFrame
} from 'app/components';
import {CheckIcon, StarIcon} from 'app/icons';
import SocialProfileButtons from "../../../components/SocialProfileButtons";
import {useEffect, useState} from 'react';
import { ReportModal } from 'app/components/ReportModal';
import { API } from 'api';
import { useAppSelector } from 'store';
import WaveStatusMessage from "../WaveStatusMessage";
import { useMediaQuery } from 'usehooks-ts'
import {compactNumber, formatEngagementRate, formatFollowerCount} from "../../../../utils/numberFormatting";


interface Props {
    data: Influencer | null;
    influencerInsight?: InfluencerInsight | null;
    mediaInsight?: MediaInsight | null;
    showAnalytics: boolean;
    analyticsPlatform?: SocialPlatformType;
    showReview?: boolean;
    reviewEnabled?: boolean;
    loadingView?: boolean;
    loadingAnalytics?: boolean;
    onViewProfile(influencer: Influencer): void;
    onViewReviews(influencer: Influencer): void;
    onSubmitReview?(): void;
    onAnalyticsChange?(platform: string): void;
}

export function InfluencerInfo({
    data,
    influencerInsight,
    mediaInsight,
    analyticsPlatform,
    showReview,
    reviewEnabled,
    showAnalytics,
    loadingView,
    loadingAnalytics,
    onViewProfile,
    onViewReviews,
    onSubmitReview,
    onAnalyticsChange,
}: Props) {

    const {
        gift: {
            item: giftItem
        },
        wave: {
            view: waveItem
        },
        profile: {
            brand
        }
    } = useAppSelector((state) => state);

    const smallDevice = useMediaQuery('(max-width: 900px)')

    useEffect(() => {

        let profileWithHighestFollowerCount: any;
        let followersCount = 0;
        if (!data?.social_profiles) {
            return;
        }
        data?.social_profiles.forEach((profile: SocialProfile) => {
            if (profile.status === 'CONNECTED' && parseInt(profile.followers) > followersCount) {
                followersCount = parseInt(profile.followers);
                profileWithHighestFollowerCount = profile
            }
        })

        if (profileWithHighestFollowerCount) {
            handleAnalyticsChange({id: profileWithHighestFollowerCount?.platform, name: profileWithHighestFollowerCount?.platform});
        }


    }, [data?.social_profiles])


    const getAnalyticsValue = (
        analyticName: 'comments' | 'followers' | 'likes' | 'engagementRate' | 'shares' | 'views' | 'rating', 
        value?: string | number
    ) => {
        if (Helpers.isNullOrUndefined(value) || (value == '0')) {
            return '----';
        }

        switch (analyticName) {
            case 'comments':
                return compactNumber(value as number);
            case 'followers':
                return formatFollowerCount(value as number);
            case 'likes':
                return compactNumber(value as number);

            case 'shares':
                return compactNumber(value as number);

            case 'views':
                return compactNumber(value as number);

            case 'engagementRate':
                return formatEngagementRate((Number.parseFloat(value as string)))

            case 'rating':
                return (
                    <>
                        {!smallDevice && <StarIcon />} {Number(value).toFixed(1)}
                    </>
                );

            default: return null;
        }
    }

    const handleAnalyticsChange = (option: OptionProps) => {
        onAnalyticsChange?.(option.id as string);
    }

    const [showReportModal, setShowReportModal] = useState(false)

    const submitReport = (form: any) => {
        if (giftItem?.uid && waveItem?.uid && form) {
            API.Shared.sendReport(giftItem.uid, waveItem.uid, form).then(() => {
                setShowReportModal(false)
            }).catch((error: any) => {throw error})
        }
    }

    return (
        <>
            <Container $loading={loadingView}>
                <Top>
                    <Desc>
                        <StyledProfileFrame
                            id={data?.uid}
                            image={data?.headshot_image}
                            width={64}
                            height={64}
                            loading={loadingView}
                        />

                        <Info>
                                <InfluencerName>
                                    {!loadingView && data?.name}
                                </InfluencerName>
                            {data && <SocialProfileButtons loading={loadingView || false} influencer={data} onViewGiftedProfile={onViewProfile} subscription={brand?.subscriptionv2}>
                                {showReview && (
                                    <Button
                                        theme={'aqua'}
                                        size={'small'}
                                        disabled={!reviewEnabled}
                                        onClick={onSubmitReview}
                                    >
                                        {reviewEnabled && <StarIcon />}
                                        {!reviewEnabled && <StyledCheck />}

                                        <span>{reviewEnabled ? 'Review' : 'Reviewed'}</span>
                                    </Button>
                                )}
                            </SocialProfileButtons>}
                        </Info>
                    </Desc>
                </Top>


                <NameAndLink>
                {onAnalyticsChange && analyticsPlatform && data?.social_profiles && data.social_profiles.length > 0 && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <StatsLabel>{!loadingView && 'Stats for:'}</StatsLabel>
                        <StyledFormSelect
                            name={'socials'}
                            noClearBtn
                            showArrow
                            label={''}
                            placeholder={!loadingView ? 'Please select a social account' : ''}
                            // options={Object.entries(data?.insights || {}).map(([k]) => ({
                            //     id: k,
                            //     name: k
                            // }))}
                            options={
                                data.social_profiles
                                    .map((prof) => {
                                        if (prof.status === 'CONNECTED') {
                                            return {id: prof.platform, name: prof.platform}
                                        } else {
                                            return {id: 'null', name: 'null'}; // or return {}
                                        }
                                    }).filter((prof) => prof.id !== 'null')
                            }
                            selected={Mapping.SupportedSocialPlatforms.find((social) => social.id === analyticsPlatform)}
                            selectedRenderer={({ id, name }) => (
                                <SelectedRenderer>
                                    {Mapping.Social(id as SocialPlatformType)}

                                    <Insight>{name}</Insight>
                                </SelectedRenderer>
                            )}
                            optionRenderer={({ id, name }) => (
                                <OptioRenderer>
                                    {Mapping.Social(id as SocialPlatformType)}

                                    <Insight>{name}</Insight>
                                </OptioRenderer>
                            )}
                            onChange={(_, option) =>
                                handleAnalyticsChange(option)}
                        />
                    </div>
                )}

                    {!loadingView && (
                        <Link onClick={() => setShowReportModal(true)}>
                            Request help
                        </Link>
                    )}

                </NameAndLink>

                {showAnalytics && (
                    <InsightList $loading={loadingView || loadingAnalytics}>

                        {influencerInsight && (
                            <>
                                <InsightItem>
                                    <span>
                                        {!loadingAnalytics &&
                                            getAnalyticsValue('followers', influencerInsight?.followers)}
                                    </span>

                                            <span>
                                        {!loadingAnalytics && 'Followers'}
                                    </span>
                                        </InsightItem>

                                        <InsightItem>
                                    <span>
                                        {!loadingAnalytics &&
                                            (getAnalyticsValue('engagementRate', influencerInsight?.engagement_rate))}
                                    </span>

                                            <span>
                                            {!loadingAnalytics && 'Engagement'}
                                    </span>
                                        </InsightItem>

                                        <InsightItem onClick={() => data && onViewReviews(data)}>
                                    <span>
                                        {!loadingAnalytics &&
                                            getAnalyticsValue('rating', influencerInsight?.rating)}
                                    </span>

                                            <span>
                                            {!loadingAnalytics && 'Rating'}
                                    </span>
                                </InsightItem>
                            </>
                        )}

                        {mediaInsight && (
                            <>
                                {mediaInsight?.likes ? (
                                    <InsightItem>
                                        <span>
                                            {!loadingAnalytics &&
                                                getAnalyticsValue('likes', mediaInsight?.likes)}
                                        </span>

                                        <span>
                                            {!loadingAnalytics && 'Likes'}
                                        </span>
                                    </InsightItem>
                                ) : null}

                                {mediaInsight?.comments ? (
                                    <InsightItem>
                                        <span>
                                            {!loadingAnalytics &&
                                                getAnalyticsValue('comments', mediaInsight?.comments)}
                                        </span>

                                        <span>
                                                {!loadingAnalytics && 'Comments'}
                                        </span>
                                    </InsightItem>
                                ) : null}

                                {mediaInsight?.shares ? (
                                    <InsightItem>
                                        <span>
                                            {!loadingAnalytics &&
                                                getAnalyticsValue('shares', mediaInsight?.shares as number)}
                                        </span>

                                        <span>
                                                {!loadingAnalytics && 'shares'}
                                        </span>
                                    </InsightItem>
                                ) : null}

                                {mediaInsight?.views ? (
                                    <InsightItem>
                                        <span>
                                            {!loadingAnalytics &&
                                                getAnalyticsValue('views', mediaInsight?.views as number)}
                                        </span>

                                        <span>
                                                {!loadingAnalytics && 'views'}
                                        </span>
                                    </InsightItem>
                                ) : null}


                                
                                {influencerInsight?.engagement_rate ? (
                                    <InsightItem>
                                        <span>
                                            {!loadingAnalytics &&
                                                (getAnalyticsValue('engagementRate', influencerInsight?.engagement_rate))}
                                        </span>

                                        <span>
                                                {!loadingAnalytics && 'Engagement'}
                                        </span>
                                    </InsightItem>
                                ) : null}
                            </>
                        )}
                    </InsightList>
                )}

                {(waveItem && !loadingView) && !showAnalytics && (
                    <WaveStatusMessage wave={waveItem} />
                )}

            </Container>
            <ReportModal show={showReportModal} onClose={() => setShowReportModal(false)} influencer={data} onSubmit={(form) => submitReport(form)} />
        </>
    );
}

const NameAndLink = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StatsLabel = styled.span`
  color: var(--grey-9);
`

const Link = styled.a`
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
  text-underline-position: under;
  text-underline-offset: 1px;
  color: var(--grey-6);
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
`


const Info = styled.div`
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2px;
    padding-right: 10px;

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
        overflow-x: scroll;
    }
`;

const Desc = styled.div`
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-right: auto;
`;

const InfluencerName = styled.div`
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 24px;
    padding-right: 10px;
    letter-spacing: -0.25px;
    word-break: break-word;
    color: var(--grey-9);
`;

const Top = styled.div`
    display: flex;
    align-items: center;
`;

const Insight = styled.span`
    text-transform: capitalize;
`;

const OptioRenderer = styled.span`
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin: auto;
`;

const SelectedRenderer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
`;

const StyledFormSelect = styled(FormSelect)`
    display: inline-block;
    margin-left: 6px;
    width: fit-content;
    box-sizing: border-box;

    > div {
        height: 40px;
        min-height: 40px;
        padding: 8px 12px;
        margin: auto;
        border-radius: 12px;
        background: white;
        border: none;

        span {
            color: var(--grey-9);
        }
    }

    @media (max-width:900px) and (min-width:0px) {

        img {
            display: none;
        }
    }
`;

const InsightItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 122px;
    height: 72px;
    padding: 10px;
    border-radius: 12px;
    box-shadow: var(--shadow-2);
    background-color: var(--background-alt);

    span {
        display: flex;
        align-items: center;
        column-gap: 3px;
        color: var(--grey-9);

        &:first-child {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.75px;
        }

        &:last-child {
            font-size: 14px;
            line-height: 24px;
            margin-top: -10px;
        }
    }

    &:last-child {
        cursor: pointer;
        transition: all 200ms ease;

        &:hover {
            background: var(--grey-4);
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        // flex-direction: row;
        align-items: center;
        span {
            font-size: 13px !important;

            &:first-child {
                font-size: 18px !important;
            }
        }
    }

`;

const InsightList = styled.div<{
    $loading?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;

    ${(props) => props.$loading && `
        pointer-events: none;

        ${InsightItem} {
            border-radius: 12px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;        

            span {
                color: transparent;
            }

            svg path {
                fill: transparent;
            }
        }
    `}
`;

const StyledProfileFrame = styled(ProfileFrame)`
`;

const Container = styled.div<{
    $loading?: boolean;
}>`
    grid-area: header;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    background-color: var(--grey-1);
    border-top-right-radius: 28px;

    ${(props) => props.$loading && `
        pointer-events: none;

        ${Info} span,
        ${Top} button,
        ${StyledFormSelect} > div {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '##############';
                visibility: hidden;
            }
        }

        ${Info} span:last-child:before {
            content: '#########';
        }
        
        ${Top} button {
            border-radius: 16px;
            
            span, svg {
                display: none;
            }

            &:before {
                content: '########';
            }
        }

        ${StyledFormSelect} > div {
            border: none;
            border-radius: 12px;

            svg path {
                fill: transparent;
            }

            span {
                color: transparent;
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        border-top-right-radius: 0;
    }
`;

const StyledCheck = styled(CheckIcon)`
        top: 6px;
        left: 5px;

        path {
            fill: var(--grey-6);
        }
`;

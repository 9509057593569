import { MouseEvent, ReactNode, useState, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import styled from 'styled-components';
import subscriptionService, {PlanType} from "../../../../../services/subscriptionService";

import { Color, Helpers } from 'utils';
import {
    Badge,
    Button,
    MediaViewer,
    PictureFrame,
    Spinner
} from 'app/components';
import {GiftModal, GiftModelType} from 'app/components/GiftModal';
import {
    ArrowUpRightIcon,
    AttachmentIcon,
    GiftIcon,
    ResendIcon,
    StarFilledIcon,
    StarIcon
} from 'app/icons';
import { MessageLink } from 'app/components/MessageLink';

import ImgBox from '../../../../../images/box.png'
import completedContentService from "../../../../../services/completedContentService";
import {deliverableToIcon} from "../../../../../utils/mapping";
import {useAppDispatch, Actions, useAppSelector} from "../../../../../store";


const completedMsgText = 'Time to get creative! When the product arrives, dive into the collaboration, and aim to finish it within two weeks. Thanks for being on board!'
const nudgeText = 'has sent a nudge. Please respond ASAP :)';
interface Props {
    index: number;
    bubble: WaveBubble;
    brandName?: string;
    onSubmitReview?: any;
    influencerImage?: string | null;
    onStatusUpdate?(status: string): void;
    /**
     * needed to show the time of
     * the last message sent by either
     * BRAND or INFLUENCER before
     * the opposite message received
     */
    lastSentMessage?: boolean;
    onResend(id?: string): void;
    onViewProfile(influencer: Influencer): void;
    influencer: Influencer;
    nextBubble?: WaveBubble;
    data?: WaveItem | null;
}

export function ThreadItem({
    index,
    bubble,
    influencerImage,
    onStatusUpdate,
    lastSentMessage,
    onSubmitReview,
    onResend,
    onViewProfile,
    influencer,
    nextBubble,
    data
}: Props) {
    const {
        global: {
            settings
        },
        profile: { brand },
    } = useAppSelector((state) => state);

    const dispatcher = useAppDispatch();

    const statusAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { delay: 0.1 }
    };

    const timeAnimation = {
        initial: { opacity: 0, height: 0 },
        animate: { opacity: 1, height: 12 },
        exit: { opacity: 0, height: 0, marginTop: -8, zIndex: -1 }
    };

    const { bubble_type, meta, sender, sender_type, sendingStatus, isSystem } = bubble;

    const incoming = sender_type !== 'BRAND';

    const [showTime, setShowTime] = useState<boolean>(lastSentMessage || true);

    const [selectedMedia, setSelectedMedia] = useState<IdName | undefined>();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [selectedWaveListAction, setSelectedWaveListAction] = useState('');

    const handleResend = () => {
        onResend(bubble.id);
    }

    const handleThreadMediaClick = (
        event: MouseEvent<HTMLImageElement | HTMLVideoElement>,
        attachment: string
    ) => {
        event.stopPropagation();

        setSelectedMedia({
            id: index,
            name: attachment
        });
    }

    const handleAcceptReject = (
        status: string,
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();

        setShowConfirmationModal(true)
        setSelectedWaveListAction(status)
    }

    const handleCloseModal = () => {
        setShowConfirmationModal(false);
        setSelectedWaveListAction('')
    }

    const handlePrimaryModal = () => {
        if (onStatusUpdate) {
            onStatusUpdate('accept');
        }
        setShowConfirmationModal(false);
        setSelectedWaveListAction('')
    }

    const handleThreadInfoClick = () => {
        if (sendingStatus && sendingStatus !== 'sent') return null;

        setShowTime((prev) => !prev);
    }

    const mapActionToGiftModalType: GiftModelType | undefined = useMemo(() => {
        switch (selectedWaveListAction) {
            case 'accept':
                if (data?.is_recently_booked) {
                    return 'creator-recently-booked-accept'
                }
                return 'wave-list-action-accept';
            case 'shortlist':
                return 'wave-list-action-shortlist';
            case 'complete':
                return 'wave-list-action-complete';
            case 'reject':
                return 'wave-list-action-reject';
            default:
                return undefined;
        }

    }, [selectedWaveListAction, data]);

    const renderStatusIndicator = () => {
        if (!sendingStatus) return null;

        let html: ReactNode;

        switch (sendingStatus) {
            case 'sending':
                html = <StyledSpinner />;
                break;

            case 'error':
                html = <ErrorIndicator onClick={handleResend} />;
                break;

            default: return null;
        }

        return (
            <StatusIndicator {...statusAnimation}>
                {html}
            </StatusIndicator>
        );
    }

    const renderThreadContent = () => {
        let html: ReactNode;

        // components here can be moved
        // to a separate file

        switch (bubble_type) {
            case 'address-sent':

                    const addressSent = meta.attributes as WaveAddressAttributes;

                    const name = addressSent?.address_name || sender.name

                    html = (
                        <MessageAddressSent $incoming={incoming}>
                            <span>{name} sent you their delivery address</span>

                            <AddressList>
                                <AddressListItem>
                                    <span>Name</span>

                                    <span>{name}</span>
                                </AddressListItem>

                                <AddressListItem>
                                    <span>Phone Number</span>

                                    <span>{addressSent?.address_mobile}</span>
                                </AddressListItem>

                                <AddressListItem>
                                    <span>Full address</span>

                                    {addressSent?.address_state && addressSent?.address_city && (
                                        <span>
                                            {addressSent?.address_street}, {addressSent?.address_city}, {addressSent?.address_state}, {addressSent?.address_postcode} {addressSent?.address_country}
                                        </span>
                                    )}
                                    {!addressSent?.address_state && !addressSent?.address_city && (
                                        <span>
                                            {addressSent?.address_street}, {addressSent?.address_postcode} {addressSent?.address_country}
                                        </span>
                                    )}
                                </AddressListItem>
                                    {!!addressSent?.address_email && (
                                        <AddressListItem>
                                            <span>Email</span>

                                            <span>{addressSent?.address_email}</span>
                                        </AddressListItem>
                                    )}
                                </AddressList>
                        </MessageAddressSent>
                    );

                break;

            case 'attachment':

                const attachmentAttributes = meta.attributes as WaveAttachmentAttributes;

                const {
                    mime_type,
                    attachment,
                } = attachmentAttributes;

                html = (
                    <>
                        <MessageAttachment $incoming={incoming}>
                            {settings && settings.allowedMimeTypes.images.includes(mime_type) ? (
                                <MediaImage
                                    layoutId={`media-viewer-${index}`}
                                    src={attachment}
                                    alt={'attachment'}
                                    onClick={(event) =>
                                        handleThreadMediaClick(event, attachment)}
                                />
                            ) : settings && settings.allowedMimeTypes.videos.includes(mime_type) ? (
                                <video controls={true}>
                                    <source src={attachment} type={mime_type} />
                                </video>
                            ) : (
                                <MediaFile>
                                    <Button
                                        theme={'aqua'}
                                        size={'small'}
                                        iconOnly={true}
                                        onClick={() => window.open(attachment)}
                                    >
                                        <AttachmentIcon />
                                    </Button>

                                    <span>{mime_type.substring(mime_type.indexOf('/') + 1)} attachment</span>
                                </MediaFile>
                            )}
                        </MessageAttachment>
                        {(
                            bubble_type === 'attachment' &&
                            settings &&
                            settings.allowedMimeTypes.images.includes(mime_type)
                        ) && (
                            <MediaViewer
                                id={selectedMedia?.id}
                                media={selectedMedia?.name}
                                onClose={() => setSelectedMedia(undefined)}
                            />
                        )}

                        {attachmentAttributes.message ? (
                            <MessageText $incoming={incoming}>
                                {attachmentAttributes.message}
                            </MessageText>
                        ) : null}
                    </>
                );

                break;

            case 'gift-deliverable':

                const { deliverables } = meta.attributes as WaveDeliverablesAttributes;

                html = (
                    <MessageGiftDeliverables $incoming={incoming}>
                        <span>Your brand needs</span>

                        <GiftDeliverables>
                            {deliverables.map(({ id, name }: Deliverable) => (
                                <Deliverable>
                                    {deliverableToIcon(id)}

                                    <span>{name}</span>
                                </Deliverable>
                            ))}
                        </GiftDeliverables>
                    </MessageGiftDeliverables>
                );

                break;

            case 'completed-content':

                const contentAttrs = meta.attributes as WaveCompletedContentAttributes;
                const post = contentAttrs.document;
                if (!settings) {
                    return null;
                }
                const externalUrl = post.items[0].externalUrl;
                const mediaUrl = completedContentService.getThumbnail(settings, post.items[0]);
                const description = `${sender.name} has posted a ${post.type.toLowerCase()} on ${completedContentService.platformToName(post.platform)}`;

                const isPro = brand?.subscription && subscriptionService.isSubscriptionType(brand?.subscription.subscription, PlanType.PRO)
                html = (
                    <>
                        <MessageNewContent $incoming={true}>
                            <span>{description}</span>

                            <NewContent className={`${!isPro && post.type.toLowerCase() === 'story' ? 'paywall' : ''}`}>
                                {!isPro && post.type.toLowerCase() === 'story' ? (
                                    <>
                                        <NeverMiss>Never miss an Instagram Story with #gifted Pro</NeverMiss>
                                        <StyledButton 
                                            onClick={() => {
                                                dispatcher(Actions.App.setShowPaymentModal(true, !subscriptionService.isSubscribed(brand?.subscription)))
                                            }}
                                            size="small" 
                                            className="upgrade-paywall"
                                        >
                                            Upgrade to Pro
                                        </StyledButton>
                                    </>
                                ) : null}
                                <a rel={'noreferrer'} target={'_blank'} href={externalUrl}><img alt={description} src={mediaUrl}/></a>
                            </NewContent>
                        </MessageNewContent>
                    </>
                );
                break;

            case 'creator-withdrawn':
                html = (
                    <MessageText $incoming={incoming}>
                        {(meta as WaveCreatorWithdrawn).message}
                    </MessageText>
                );
                break;

            case 'review-received':

                const reviewAttributes = meta.attributes as WaveReviewAttributes;

                const {
                    rating = Helpers.generateInt(1, 5),
                    reviewed_at = bubble.created_at,
                    review
                } = reviewAttributes;

                html = (
                    <MessageProductReviewed $incoming={incoming}>
                        <span>{sender.name} gave you a review</span>

                        <ProductReviewed>
                            <ProductReviewedHeader>
                                <span>
                                    {Array(5).fill('').map((_, i) => (
                                        i <= rating ? (
                                            <StarFilledIcon key={i} />
                                        ) : (
                                            <StarIcon key={i} />
                                        )
                                    ))}
                                </span>

                                <ProductReviewedDate>
                                    {Helpers.formatDate(reviewed_at, 'dd MMMM, Y')}
                                </ProductReviewedDate>
                            </ProductReviewedHeader>

                            <ProductReviewedBody>
                                {/* <span>{review}</span> */}

                                <span>
                                    {review}
                                </span>
                            </ProductReviewedBody>
                        </ProductReviewed>
                    </MessageProductReviewed>
                );

                break;

            case 'request-review':

                const reviewAttrs = meta.attributes as WaveReviewAttributes;

                const onClick = () => {
                    if (!reviewAttrs.has_review_received) {
                        onSubmitReview();
                    }
                }

                html = (
                    <MessageRequestReview $incoming={incoming}>
                        <Review>
                            <ReviewTitle>
                                {sender.name} is requesting a review
                            </ReviewTitle>

                            <ReviewDesc>
                                Give this creator and the #gifted team feedback on this collaboration.
                            </ReviewDesc>

                            {/* @ts-ignore */}
                            <Button disabled={data?.review || reviewAttrs.has_review_received} size="small"
                                    onClick={onClick}>{reviewAttrs.has_review_received ? 'Review received' : 'Review'}
                            </Button>
                        </Review>
                    </MessageRequestReview>
                );

                break;

            case 'shopify-order-shipment':
                const {
                    gift_card_code,
                    tracking_infos,
                    status,
                    order_id,
                    order_url
                } = meta.attributes as WaveOrderShipmentAttributes;

                html = (
                    <MessageShopifyOrderShipment $incoming={incoming}>

                        <ShopifyMessageBetween>

                            <ShopifyMessageIcon>
                                <img width="100%" src={ImgBox} alt="giftbox" />
                            </ShopifyMessageIcon>

                            <ShopifyMessageContent>
                                <h1>Congratulations!</h1>

                                <span>Order <a rel={'noreferrer'} target={'_blank'} href={order_url}>#{order_id}</a> has been {tracking_infos ? 'updated' : 'created'} in Shopify.</span>

                                {gift_card_code ? (
                                    <ShopifyOrderShipment>
                                        <ShipmentInfo>
                                            <span>A gift card was issued</span>
                                            <span>{gift_card_code}</span>
                                            {status && (
                                                <ShipmentStatus>
                                                    {status}
                                                </ShipmentStatus>
                                            )}
                                        </ShipmentInfo>
                                    </ShopifyOrderShipment>
                                ) : (
                                    tracking_infos?.map(({ number, url, status: trackingStatus }) => (
                                        <ShopifyOrderShipment>
                                            <ShipmentInfo>
                                                <span>{number}</span>

                                                <span>{trackingStatus || status}</span>
                                            </ShipmentInfo>

                                            <ShipmentUrl onClick={() => window.open(url)}>
                                                <ArrowUpRightIcon />
                                            </ShipmentUrl>
                                        </ShopifyOrderShipment>
                                    ))
                                )}

                            </ShopifyMessageContent>

                        </ShopifyMessageBetween>

                    </MessageShopifyOrderShipment>
                );

                break;

            case 'shopify-selection-fallback':
                html = (
                    <>
                        <MessageText
                            $incoming={incoming}
                            $hasLink={true}
                        >
                            <span>{meta.message}</span>

                            <MessageLink url={'/integrations'} incoming={incoming} ctaMode={true} linkText="Reconnect Shopify" />

                        </MessageText>
                    </>
                );

                break;

            case 'user-message' || 'silent-wave':
                let url = Helpers.containsUrl(meta.message || '');

                if (url) {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        url = 'https://' + url;
                    }

                    html = (
                        <>
                            <MessageText
                                $incoming={incoming}
                                $hasLink={true}
                            >

                                <span>{meta.message}</span>

                                <MessageLink url={url} incoming={incoming} />

                            </MessageText>
                        </>
                    );
                } else {
                    html = (
                        <>
                            <MessageText $incoming={meta.message === completedMsgText ? true : incoming}>
                                {meta.message === completedMsgText ? <><b>Creator selected: {Helpers.formatDate(bubble.created_at, 'chat-thread')}</b><br /></> : null}
                                {meta.message}
                                {influencer && meta.message?.includes('has applied for your gift.') && <span>{' '}<span onClick={() => onViewProfile(influencer as Influencer)} style={{textDecoration: 'underline', fontWeight: 'bold', color: 'var(--grey-6)', cursor: 'pointer'}}>View their profile</span> to see if they are suitable.</span>}
                            </MessageText>
                        </>
                    );
                }

                break;

            case 'personal-information-detected':
                html = (
                    <>
                        <MessageText $incoming={incoming}>
                            {meta.message}
                            <div className={'accepted-btn-center'}>
                                <Button
                                    theme={'aqua'}
                                    className={'accepted-btn'}
                                    onClick={(event) =>
                                        handleAcceptReject('accept', event)}
                                    size='small'
                                    disabled={data?.status==='ACCEPTED'}
                                >
                                    Accept this creator
                                </Button>
                            </div>
                        </MessageText>
                    </>
                );

                break;

            case 'nudge':
                html = (
                    <>
                        <MessageText $incoming={true}>
                            {meta.message}
                        </MessageText>
                    </>
                )
                break;
            case 'shopify-reminder':
                html = (
                    <>
                        <MessageText $incoming={true}>
                            {meta.message}
                        </MessageText>
                    </>
                )
                break;

            default: return null;
        }

        return (
            <ThreadInfo onClick={handleThreadInfoClick}>
                {html}

                <AnimatePresence>
                    {
                        (
                            showTime &&
                            !['sending', 'error'].includes(sendingStatus || '')
                        ) && (
                            <ThreadInfoTime {...timeAnimation} style={{marginBottom: '4px'}}>
                                {Helpers.formatDate(bubble.created_at, 'chat-thread')}
                                {bubble.seen_at && bubble.sender_type === 'BRAND' ? (
                                    <Read>Read</Read>
                                ) : bubble.sender_type === 'BRAND' ? <Read>Not read</Read> : null}
                            </ThreadInfoTime>
                        )
                    }
                </AnimatePresence>

                {renderStatusIndicator()}
            </ThreadInfo>
        );
    }
    return (
        <>
            <Container $incoming={incoming}>
             {incoming && (
                 <StyledPictureFrame
                       image={isSystem ? '/gifted-avatar.png' : influencerImage}
                        width={42}
                       height={42}
                 />
             )}

                {renderThreadContent()}
            </Container>
            {mapActionToGiftModalType && (
                <GiftModal
                    primaryAction={{name: `Yes, I'm sure`, action: () => handlePrimaryModal()}}
                    secondaryAction={{name: 'Cancel', action: () => handleCloseModal()}}
                    show={showConfirmationModal}
                    onClose={() => handleCloseModal()}
                    type={mapActionToGiftModalType}
                />
            )}
        </>
    );
}

const StyledButton = styled(Button)`

    &.upgrade-paywall {
        position: absolute;
        margin: auto;
        inset: 0;
        width: 170px;
        z-index: 100;

        &:hover {
            background: #262161;
            color: white;
        }

        &:focus {
            transform: none;
        }
    }
`

const NeverMiss = styled.div`
    position: absolute;
    margin: auto;
    inset: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 2px rgba(0,0,0,.7);
    z-index: 100;
`

const StyledSpinner = styled(Spinner)`
    width: 22px;
    height: 22px;
    border-width: 2.25px;
`;

const ErrorIndicator = styled(ResendIcon)`
    cursor: pointer;
`;

const StatusIndicator = styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(38, 33, 97, 0.25);
    border-radius: 12px;

    ${ErrorIndicator} {
        path {
            fill: var(--error-active);
        }
    }
`;

const StyledPictureFrame = styled(PictureFrame)`
    border-radius: 12px;

    svg {
        width: 24px;
    }
`;

const BaseMessage = styled.div<{
    $incoming?: boolean;
}>`
    padding: 8px 12px;
    border-radius: 12px;
    background-color: var(--blue);
    word-break: break-word;
    color: var(--text-alt);

    ${(props) => props.$incoming ? `
        align-self: flex-start;
        background-color: var(--grey-2);
        color: var(--grey-9);
    ` : `
        align-self: flex-end;
    `}
`;

const AddressListItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 8px 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--grey-4);
    }

    span {
        &:first-child {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            color: var(--grey-6);
        }

        &:last-child {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin-top: -1px;
            color: var(--grey-9);
        }
    }
`;

const AddressList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`

const MessageAddressSent = styled(BaseMessage)`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;

    > span {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const MediaFile = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    line-height: 16px;

    button {
        width: 36px;
        height: 36px;
        padding: 8px;
        border-radius: 12px;
    }
`;

const MediaImage = styled(motion.img)`
    user-drag: none;
`;

const MessageAttachment = styled(BaseMessage)`
    padding: 12px;

    img, video {
        width: 100%;
        height: auto;
        border-radius: 12px;
    }
`;

const Deliverable = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 48px;
    padding: 12px 20px;
    border: 1px solid var(--grey-4);
    border-radius: 100px;
    background-color: var(--background-alt);

    svg {
        width: 20px;
        height: 20px;
    }

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: var(--grey-7);
    }
`;

const GiftDeliverables = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const MessageGiftDeliverables = styled(BaseMessage)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    min-width: 254px;
    padding: 8px 12px;

    > span {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const StyledGiftIcon = styled(GiftIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: auto;
    transform: translate(-50%, -50%);

    path {
        fill: var(--blue);
    }
`;

const ReviewImage = styled.div<{
    $image?: string;
}>`
    position: relative;
    width: 100%;
    height: 124px;
    background-color: var(--grey-2);

    ${(props) => props.$image && `
        background-image: url(${props.$image});
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        ${StyledGiftIcon} {
            display: none;
        }
    `}
`;

const ReviewGiftName = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 12px;
    color: var(--grey-7);
`;

const ReviewDesc = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    font-size: 14px;
`;

const ReviewTitle = styled.span`
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-alt);
    text-align: center;
`;

const Review = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const MessageRequestReview = styled(BaseMessage)`
    width: 340px;
    padding: 12px;

    ${(props) => props.$incoming && `
        ${ReviewTitle} {
            color: var(--grey-9);
        }

        ${ReviewImage} {
            background-color: var(--blue);
        }

        ${StyledGiftIcon} {
            path {
                fill: var(--background-alt);
            }
        }

        ${ReviewGiftName} {
            color: var(--grey-9);
        }
    `}


    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
    }

`;

const ShipmentStatus = styled(Badge)`
    background-color: ${() => Color.hexVarToRGBA('--aqua', 0.25)};
    color: var(--success-active);
`;

const ShipmentInfo = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    span {
        &:first-child {
            font-size: 14px;
            line-height: 24px;
            color: var(--grey-9);
        }

        &:last-child {
            align-self: flex-start;
        }
    }
`;

const ShipmentUrl = styled.span`
    margin-left: auto;
`;

const ShopifyOrderShipment = styled.div`
    display: flex;
    padding: 10px 12px;
    border-radius: 12px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    margin-top: 10px;
`;

const MessageShopifyOrderShipment = styled(BaseMessage)`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background: var(--grey-2);

    > span {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: var(--grey-8);
    }
    
    a {
        color: var(--blue);
    }
`;

const ShopifyMessageBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
`

const ShopifyMessageIcon = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width: 20%;
`

const ShopifyMessageContent = styled.div`
    width: 75%;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-size: 16px;
        color: var(--blue);
        margin: 4px 0px;
    }

    span {
        color: var(--grey-9);
        font-size: 14px;
    }
`

const NewContent = styled.div`
    border-radius: 20px;
    background-color: var(--background-alt);
    overflow: hidden;

    iframe {
        border: none !important;
    }

    a > img {
      width: 100%;
    }

    &.paywall {
        position: relative;

        a > img {
            filter: blur(5px);
        }
    }
`;

const MessageNewContent = styled(BaseMessage)`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 12px;

    > span {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const ProductReviewedBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;

    span {
        &:first-child {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            color: var(--grey-6);
        }
    }
`;

const ProductReviewedDate = styled.span`
    font-size: 12px;
    align-self: flex-start;
    line-height: 16px;
    margin-top: 8px;
    margin-left: auto;
    color: var(--grey-6);
`;

const ProductReviewedHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg path {
        fill: var(--yellow);
    }
`;

const ProductReviewed = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px;
    border-radius: 16px;
    box-shadow: var(--shadow-2);
    background-color: var(--background-alt);
`;

const MessageProductReviewed = styled(BaseMessage)`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 12px;

    > span {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const MessageText = styled(BaseMessage) <{
    $hasLink?: boolean;
}>`
    font-size: 14px;
    line-height: 24px;
    white-space: pre-line;

    ${(props) => props.$hasLink && `
        padding: 12px 12px 8px;
    `}

    ${(props) => props.$incoming && `
        color: var(--grey-8);
    `}
    
    .accepted-btn-center {
        display: flex;
        justify-content: center;
    }
    
    .accepted-btn {
        margin-top: 4px;
    }
`;

const ThreadInfoTime = styled(motion.div)`
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: right;
    color: var(--grey-6);
`;

const ThreadInfo = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    position: relative;
`;

const Container = styled.div<{
    $incoming?: boolean;
}>`
    align-self: flex-end;
    display: flex;
    column-gap: 8px;

    ${(props) => props.$incoming && `
        align-self: flex-start;

        ${StyledPictureFrame} {
            align-self: center;
            background-color: var(--grey-2);

            svg path {
                fill: var(--blue);
            }
        }

        ${ThreadInfoTime} {
            text-align: left;
        }
    `}
`;

const Read = styled.span`
    display: block;
`

import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import {
    Alert,
    Button,
    Loader,
    StatusLoader,
    Tab,
    TabItemProps
} from 'app/components';
import { PrivateLayout } from 'app/layouts';
import { GiftList } from './GiftList';

import { PendingBrand } from 'app/components/PendingBrand';
import { API } from 'api';
import {Helpers} from "../../../utils";
import errorReporter from 'services/errorReporter';
import { Showcase } from './Showcase';
import AnalyticsEvents from '../../../services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';
import { TeamsModal } from 'app/components/TeamsModal';

const tabItems = [
    { id: 'active', name: 'Active Gifts' },
    { id: 'past', name: 'Past Gifts' }
];

export function DashboardPage() {
    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const {
        gift: { list, status, loading },
        profile: {brand, representative},
        wave: { lastReceivedBubble },
        auth,
        global: { settings }
    } = useAppSelector((store) => store);

    const params = new URLSearchParams(window.location.search);
    const locationTab = params.get('tab');

    const [selectedTab, setSelectedTab] = useState<TabItemProps>(locationTab === 'past' ? tabItems[1] : tabItems[0]);
    const [fetchingTab, setFetchingTab] = useState(false)
    const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
    const [dismissModals, setDismissModals] = useState(false);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );

    const showcase = (settings?.features['dashboard.showcase'] && (!brand?.latest_gift && !list.length && !loading && selectedTab.id === 'active'));

    const sendVerificationEmail = () => {

        representative?.uid && API.Profile.sendVerificationEmail(representative?.uid)
            .then(() => setSentVerificationEmail(true)).catch((error: any) => {errorReporter.report('Error getting sending verification email', error)})
    }

    useEffect(() => {
        if (selectedTab && auth.user) {
            setFetchingTab(true)
            dispatcher(Actions.Gift.getByType(selectedTab?.id)).catch((error: any) => {throw error});
            setFetchingTab(false)
        }
        analyticsEvents.dashboardTabOpened(selectedTab.id);


    }, [selectedTab, auth.user]);

    useEffect(() => {
        if (brand && representative) {
            if (!representative?.name || !representative.mobile) {
                navigate('/representative-profile', {})
            }
        }
    }, [brand, representative]);

    useEffect(() => {
        analyticsEvents.dashboardOpened();
        localStorage.removeItem('teamInviteFinish');
    }, []);    


    useEffect(() => {
        lastReceivedBubble && dispatcher(Actions.Gift.getByType(selectedTab.id, true)).catch((error: any) => {throw error});
    }, [lastReceivedBubble])

    const getMessage = () => {
        switch (status) {
            case 'error':
                return 'Request failed';

            default: return '';
        }
    }

    const handleNavigate = (giftId: string, status: GiftStatus) => {
        if (status === 'ACTIVE' || status === 'COMPLETED' || status === 'SCHEDULED' || status === 'CLOSED' || status === 'REJECTED' || status === 'SUBMITTED') {
            navigate(`/gift-view/${giftId}`);
        } else {
            navigate(`/create/${giftId}`);
        }
    }

    const goToProfile = () => {
        navigate('/representative-profile', {});
    }

    const renderSubscriptionAlert = () => {


        if (!settings?.features.subscriptions) {
            return
        }

        if (!brand?.subscription?.status || brand?.subscription.status === 'active') {
            return
        }

        let xDays = ''
        if (brand?.subscription.expires_at) {
            xDays = Helpers.getTimeAgo(brand.subscription.expires_at) as string;
        }

        interface Message {
            type: string;
            title: string;
            body: string;
            action: string;
            actionUrl: string;
            newTab?: boolean;
          }

          interface MessagesByStatus {
            trialing: Message;
            canceled: Message;
            unpaid: Message;
            past_due: Message;
            incomplete: Message;
            free: null;
            incomplete_expired: null;
            paused: null;
          }


        const messagesByStatus: MessagesByStatus = {
            'trialing': {
                type: 'info',
                title: `Your trial ends in ${xDays}`,
                body: `Your trial will end on ${brand?.subscription.expires_at}`,
                action: 'Discover #gifted features',
                actionUrl: `https://www.hashgifted.com/features`,
                newTab: true
            },
            'canceled': {
                type: 'warning',
                title: 'Your subscription is canceled',
                body: `You will lose the ability to post new gifts and collaborate with Creators.`,
                action: 'Subscribe',
                actionUrl: '/subscription-billing'
            },
            'unpaid': {
                type: 'error',
                title: `There was a problem charging for your subscription`,
                body: 'We were unable to charge your payment method.',
                action: 'Update payment method',
                actionUrl: '/subscription-billing'
            },
            'incomplete': {
                type: 'error',
                title: `There was a problem charging for your subscription`,
                body: 'We were unable to charge your payment method.',
                action: 'Update payment method',
                actionUrl: '/subscription-billing'
            },
            'past_due': {
                type: 'error',
                title: `There was a problem charging for your subscription`,
                body: 'We were unable to charge your payment method.',
                action: 'Update payment method',
                actionUrl: '/subscription-billing'
            },
            'free': null,
            'incomplete_expired': null,
            'paused': null
        }

        const data = messagesByStatus[brand?.subscription.status] as Message;

        if (data) {
            return (
                <Alert
                    //@ts-ignore
                    type={data.type}
                    title={data.title}
                    message={data.body}
                    style={{ backgroundColor: "white" }}
                >
                    {data.action && data.actionUrl && (
                        <span className="cta" onClick={() => {data.newTab ? window.open(data.actionUrl, '_blank') : navigate(data.actionUrl, {})}}>{data.action}</span>
                    )}
                </Alert>

            )
        }


    }

    const renderBlankInfo = () => {
        switch (selectedTab.id) {
            case 'active':
                return (
                    <Showcase firstTime={false} profile={representative as RepresentativeProfile} />
                )
            case 'past':
                return (
                    <>
                        <h1>What are past gifts?</h1>
                        <h3 className="heading-three">Gifts you have completed are kept here and can be reposted at any time.</h3>
                        <StyledButton
                            size={'medium'}
                            theme="aqua"
                            onClick={() => navigate('/create')}
                        >
                            Create your first gift
                        </StyledButton>
                    </>

                )
        }
    }

    const closeModal = (modalName: string) => {
        if (brand?.uid) {
            API.Profile.setModalHistory(brand?.uid, modalName).then(() => {
                if (representative?.uid) {
                    dispatcher(Actions.Profile.getProfiles(representative?.uid, brand?.uid)
                ).catch((error: any) => {throw error})}
                
                setDismissModals(true);

            }).catch((error: any) => {
                errorReporter.report('Error dismissing modal', error);
            });
        }
    }

    return (

        <>
        <PrivateLayout>

            {!settings || !settings?.features || !brand || !list || !auth || !auth.user || !representative || ((loading) && !list.length) ? (
                <LoadContainer>
                    <Loader status="idle" />
                </LoadContainer>
            ) : showcase ? (
                <Showcase firstTime={showcase} profile={representative as RepresentativeProfile} />
            ) : (
                brand?.status === 'PENDING' ? (
                    <PendingBrand />
                ) : (
                    <Container data-testid={'dashboard-page'}>


                        <TopNotifications>
                            <NoteContainer>
                                {!sentVerificationEmail && !representative?.auth.email_verified_at && (
                                    <Alert type={"info"} title={`Your email address is not verified`} ctaLabel={`Send a verification email`} ctaAction={sendVerificationEmail}>
                                        <span><b>{representative?.auth.email}</b> is unverified. If this is not your email address, please <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={goToProfile}>update your profile.</span>
                                        </span>
                                    </Alert>
                                )}

                                {representative?.auth.email_verified_at && renderSubscriptionAlert()}

                            </NoteContainer>
                        </TopNotifications>



                        <StyledTab
                            items={tabItems}
                            selected={selectedTab}
                            $loading={(loading || !auth.user)}
                            onChange={setSelectedTab}
                        >

                            {(!loading && auth.user && !fetchingTab) && list.length === 1 && selectedTab.id === 'submitted' && (
                                <TopNotifications>
                                    <NoteContainer>
                                        <Alert type={"info"} title={`What happens next?`}>
                                            <span>Submitted gifts are reviewed by the #gifted team. (Usually in 24 hours!) Once your gift is approved you can start matching with creators. Until then, hold tight or create another gift.</span>
                                        </Alert>
                                    </NoteContainer>
                                </TopNotifications>
                            )}

                            <GiftList
                                items={list}
                                brand={brand}
                                //@ts-ignore
                                refresh={async () => await dispatcher(Actions.Gift.getByType(selectedTab?.id)).catch((error: any) => {throw error})}
                                loading={(loading || !auth.user)}
                                onClick={handleNavigate}
                            />

                            {((!loading && auth.user && !fetchingTab) && list.length === 0) && (
                                <BlankContainer>
                                    {renderBlankInfo()}
                                </BlankContainer>
                            )}

                        </StyledTab>

                    </Container>
                )
            )}

            <StatusLoader
                show={status === 'error'}
                status={status}
                title={'My Gifts'}
                message={getMessage()}
            />
        </PrivateLayout>

        {brand && !brand?.modal_histories?.team_invite_modal && !dismissModals  ? (
            <TeamsModal 
                show={true}
                onClose={() => closeModal('team_invite_modal')}
                refresh={() => {}}
            />
         ) : null}
        </>
    );
}

const LoadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
`

const StyledButton = styled(Button)`
    margin-top: 12px;
`;

const StyledTab = styled(Tab) <{
    $loading?: boolean;
}>`
    margin-top: 28px;

    ${(props) => props.$loading && `
        pointer-events: none;
    `}

    
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const BlankContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        color: #262262;
        margin-top: 0px;
        margin-bottom: 25px;
        color: #262262;
        font-size: 32px;
        line-height: 1.3;
        font-weight: 700;    
        width: 100%;
        max-width: 780px;    
    }

    .heading-three {
        color: #262262;
        font-size: 22px;
        line-height: 34px;  
        width: 100%;
        max-width: 780px;    
        font-weight: 400;
        margin-bottom: 50px;  
    }

    .cta {
        color: var(--blue);
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 35px;
    }
`;

const TopNotifications = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
    
    &.margin-down{
      margin-top: 0px;
      margin-bottom: 24px;
    }
  
`

const NoteContainer = styled.div`
    width: 1037px;

    @media (max-width:900px) and (min-width:0px) {
        width: 80%;

        .additional-items {
            padding-top: 0px;
        }

        .cta {
            padding-top: 0px;
        }

        .left-alert {
            display: none;
        }

        span, .title, .cta {
            font-size: 12px;
        }
    }
`
